<template>
<div>
    <!-- My Information -->
    <form @submit.prevent="checkValidation()" autocomplete="off" data-vv-scope="senddetails">
        <div class="my-info mt-8">
            <div class="vx-row justify-center">
                <div class="vx-col lg:w-2/3 xl:w-2/3 w-full">
                    <vx-card>
                        <div class="profile-icon">
                            <span class="main-icon">
                                <img src="../../assets/images/install.png" />
                            </span>
                            <div class="card-title flex-wrap justify-center">
                                <h2 class="text-white">{{ LabelConstant.headingLabelInstallationScript }}</h2>
                                <!-- <vs-button title="Help" @click="popupActive=true" type="filled" icon-pack="feather" icon="icon-help-circle"></vs-button> -->
                            </div>
                        </div>
                        <div class="update-info-form">
                            <div class="vx-row mb-6" id="v-step-0">
                                <div class="vx-col w-full">
                                    <p class="mb-4 small-tag"> {{ LabelConstant.infoLabelInstallation }}</p>

                                    <div class="textarea-main">
                                        <vs-textarea v-model="installationCode" class="form-textarea mb-4" :readonly="true" id="myInput" />
                                        <vs-button :title="LabelConstant.buttonTitleCopyToClipboard" type="filled" icon-pack="feather" icon="icon-copy" class="mr-2 float-left bg-actionbutton" @click.prevent="copyText()"></vs-button>
                                    </div>
                                    <!-- <p class="mb-4 small-tag">Insert this HTML to add an end-user cookie consent to your website.</p>
                                    <div class="textarea-main">
                                        <vs-textarea v-model="installationCode" class="form-textarea mb-4" :readonly="true" id="myInput" />
                                        <vs-button :title="LabelConstant.buttonTitleCopyToClipboard" type="filled" icon-pack="feather" icon="icon-copy" class="mr-2 float-left bg-actionbutton" @click.prevent="copyText()"></vs-button>
                                    </div>
                                    <p class="small-tag">{{ LabelConstant.inputLabelInfoInstallation }}</p> -->
                                </div>
                                      
                            </div>

                            <template>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <p class="mb-4 small-tag">
                                            <b>{{ LabelConstant.headingLabelSendInstallationInstructions }}</b>
                                        </p>
                                    </div>
                                    <div class="vx-col w-full mb-6">
                                        <vs-input class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border :label="LabelConstant.inputLabelEnterEmail" v-model="enterEmail" name="enterEmail" v-validate="'required|email'" />
                                        <span class="text-danger text-sm">{{ errors.first('senddetails.enterEmail') }}</span>
                                    </div>
                                    <div class="vx-col w-full">
                                        <span class="vs-input--label">{{ LabelConstant.inputLabelMessageToRecipient }}</span>
                                        <vs-textarea v-model="txtMsg" class="form-textarea mb-4" />
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full mt-6">
                                        <vs-button type="filled" class="mr-4 mb-2" color="primary" button="submit">{{ LabelConstant.buttonLabelSendInstallationInstructions }}</vs-button>
                                        <vs-button color="grey" type="border" class="mb-2 grey-btn" @click="getInstallation()">{{ LabelConstant.buttonLabelReset }}</vs-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </vx-card>
                </div>
            </div>
        </div>
    </form>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import {
    Validator
} from "vee-validate";
//import Cookie from "js-cookie";


const dict = {
    custom: {
        enterEmail: {
            required: "Please enter email",
            email: "Please enter valid email"
        }
    }
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        HelpModal
    },
    data() {
        return {
            /* Popup */
            popupActive: false,
            enterEmail: null,
            txtMsg: null,
            installationCode: null,
              myOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish'
                }
            },
           
        };
    },
    created() {
       
    },
    mounted() {
        this.getInstallation();
    },
    methods: {
        // copy text functionality
        copyText() {
            var copyText = document.getElementById("myInput");
            copyText.select();
            document.execCommand("copy");

        },
        /* Popup Close */
        closepopup() {
            this.popupActive = false;
        },
        // get installtion data
        async getInstallation() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigInstallation/GetInstallation")
                .then(response => {
                    let data = response.data;
                    this.installationCode = data.Installation_Code;
                    this.enterEmail = data.Email;
                    this.txtMsg = data.Message_Text;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // check validation 
        async checkValidation() {
            this.$validator.validateAll("senddetails").then(async result => {
                if (result) {
                    this.sendInstallationDetails();
                }
            });
        },
        // call api to send installation details 
        async sendInstallationDetails() {
            this.$vs.loading();
            let input = {
                Email: this.enterEmail,
                Installation_Code: this.installationCode,
                Message_Text: this.txtMsg
            };

            await this.axios
                .post("/ws/ConfigInstallation/SendInstallationCode", input)
                .then(() => {
                    this.$vs.notify({
                        title: "Success",
                        text: "Your information has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.getInstallation();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Erorr",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                });
        }
    }
};
</script>
